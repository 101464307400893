(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
jQuery(document).ready(function() {
	function constructor_slider() {
		if( $(".category_consumer .owl-carousel").length || $(".category_trade .owl-carousel").length ||
		$(".subcategory_consumer .owl-carousel").length || $(".subcategory_trade .owl-carousel").length ||
		$(".subcategory_consumer_recently_viewed .owl-carousel").length ||
		$(".subcategory_trade_recently_viewed .owl-carousel").length || $(".search_consumer .owl-carousel").length ||
		$(".search_trade .owl-carousel").length || $(".no_search_consumer .owl-carousel").length ||
		$(".no_search_trade .owl-carousel").length || $(".cart_top_consumer .owl-carousel").length ||
		$(".cart_top_trade .owl-carousel").length || $(".cart_lower_consumer .owl-carousel").length ||
		$(".cart_lower_trade .owl-carousel").length || $(".atc_pop_up_consumer .owl-carousel").length ||
		$(".atc_pop_up_trade .owl-carousel").length || $(".brand_consumer .owl-carousel").length ||
		$(".brand_trade .owl-carousel").length || $(".home_trade .owl-carousel").length ||
		$(".home_consumer .owl-carousel").length || $(".promotions_consumer .owl-carousel").length
		|| $(".promotions_trade .owl-carousel").length || $(".home_recently_viewed_consumer .owl-carousel").length || $(".home_recently_viewed_trade .owl-carousel").length) {
			$(".promotions_consumer .owl-carousel, .promotions_trade .owl-carousel, .category_consumer .owl-carousel, .category_trade .owl-carousel, .subcategory_consumer .owl-carousel, .subcategory_trade .owl-carousel, .subcategory_consumer_recently_viewed .owl-carousel, .subcategory_trade_recently_viewed .owl-carousel, .search_consumer .owl-carousel, .search_trade .owl-carousel, .no_search_consumer .owl-carousel, .no_search_trade .owl-carousel, .cart_top_consumer .owl-carousel, .cart_top_trade .owl-carousel, .cart_lower_consumer .owl-carousel, .cart_lower_trade .owl-carousel, .atc_pop_up_consumer .owl-carousel, .atc_pop_up_trade .owl-carousel, .brand_consumer .owl-carousel, .brand_trade .owl-carousel, .home_consumer .owl-carousel, .home_trade .owl-carousel, .home_recently_viewed_consumer .owl-carousel, .home_recently_viewed_trade .owl-carousel").owlCarousel({
				loop: true,
				responsiveClass: true,
				nav: true,
				dots: false,
				responsive: {
					0: {
						items: 2,
						slideBy: 2
					},
					768: {
						items: 3,
						slideBy: 3
					},
					991: {
						items: 4,
						slideBy: 4
					},
					1200: {
						items: 5,
						slideBy: 5
					}
				}
			}).show();
		}

		if($(".pdp_footer_consumer .owl-carousel").length || $(".pdp_footer_trade .owl-carousel").length) {
			$(".pdp_footer_consumer .owl-carousel, .pdp_footer_trade .owl-carousel").owlCarousel({
				loop: true,
				responsiveClass: true,
				nav: true,
				dots: false,
				margin: 10,
				responsive: {
					0: {
						items: 2,
						slideBy: 2,
						margin: 20
					},
					600: {
						items: 3,
						slideBy: 3
					},
					992: {
						items: 7,
						slideBy: 4,
						margin: 10
					}
				}
			}).show();
		}

		/* PDP - You May Also Like Slider && Similar Items Slider*/
		if($(".pdp_left_consumer").length || $(".pdp_left_trade").length || $(".pdp_open_box_left").length) {
			$(".pdp_left_consumer .owl-carousel, .pdp_left_trade .owl-carousel, .pdp_open_box_left .owl-carousel").owlCarousel({
				loop: true,
				responsiveClass: true,
				margin: 8,
				nav: true,
				dots: false,
				responsive: {
					0: {
						items: 2,
						slideBy: 2,
						margin: 54
					},
					600: {
						items: 3,
						slideBy: 3,
						margin: 11
					},
					992: {
						items: 8,
						slideBy: 8,
						margin: 11
					}
				}
			}).show();
		}
	}

	//Constructor Recommendations AJAX
    const recommendationDivs = document.querySelectorAll('.conRecommendation[data-loaded="0"]');

    recommendationDivs.forEach(function(div) {
        const section = div.getAttribute('data-section');
        const target = div.getAttribute('data-target');
        const pid = div.getAttribute('data-pid');
        const search = div.getAttribute('data-search');
        const base_url = div.getAttribute('data-url');

        var url = app.util.appendParamToURL(base_url, "secion", section);

		if(pid){
			url = app.util.appendParamToURL(url, "productId", pid);
		}
		else if(search){
			url = app.util.appendParamToURL(url, "searchphrase", search);
		}
        url = app.util.appendParamToURL(url, "format", 'ajax');

		$.ajax({
			dataType: 'html',
			url: url,
		}).success(function (response) {

			$('.conRecommendation.' + target).removeClass("loading").html(response);
			div.setAttribute('data-loaded', '1');

			if($('.conRecommendation.' + target + ":not(:has(div))").length && $('.conRecommendation.' + target).attr("data-loaded",1)) {
				$('.conRecommendation.' + target).addClass("d-none");
			}

			//Cart Page Only
			if($('.explore').length) {
				if($(".conRecommendation.cart_top").hasClass("d-none") && $(".conRecommendation.cart_lower").hasClass("d-none")) {
					$('.explore').addClass('d-none');
				}
			}

			if($('.conRecommendation').length) {
				$('.conRecommendation').each(function() {
					$(this).find(".con-item-price .open-box-priceHeading").parents(".con-item-price").addClass("open-box-price");
				});
			}

		}).error(function (xhr, textStatus) {
			if (textStatus === 'parsererror') {
				console.log(Resources.BAD_RESPONSE);
			}
		}).complete(function(){
			constructor_slider();
			attachConstructorPodClickEvent();
		});
    });

	// Constructor PODS click event
	function attachConstructorPodClickEvent() {
		var anchors = document.querySelectorAll('.bloom-recommendations .con-item-name, .bloom-recommendations .thumb-link, .bloom-recommendations .name-link, .bloom-recommendations .see-more, .bloom-recommendations .customCon');
		anchors.forEach(function(anchor) {
			anchor.removeEventListener('click', handlePodClick); // Remove existing listener
			anchor.addEventListener('click', handlePodClick); // Add new listener
		});
	}

	// Event handler function
	function handlePodClick(e) {
		var podId = this.closest('.bloom-recommendations').getAttribute('data-cnstrc-recommendations-pod-id');
		if (typeof _satellite != 'undefined'){
			_satellite.track('ConstructorPodClicked', { podName: podId });
		}
	}

	// Initial call to attach event listeners in case there are already pods loaded on the page
	attachConstructorPodClickEvent();
});

},{}]},{},[1]);
